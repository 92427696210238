<template>
  <div class="container">
    <div class="top_container">
      <span>常见问题</span>
    </div>
    <div class="search_container">
      <div class="search_box">
        <form action="" @submit.prevent="sub">
          <a-input v-model="form.title" @pressEnter="search" type="search" ref="userNameInput" placeholder="关键字搜索">
            <a-icon style="color: #fff !important;font-size:20px" slot="prefix" type="search" />
          </a-input>
        </form>
      </div>  
    </div>
    <div class="wrapper" ref="wrapper">
      <div class="content">
        <div class="pulldown-wrapper loading_text">
          <div v-show="beforePullDown">
            <span>下拉刷新数据</span>
          </div>
          <div v-show="!beforePullDown">
            <div v-show="isPullingDown">
              <span>加载中...</span>
            </div>
            <div v-show="!isPullingDown">
              <span>刷新成功</span>
            </div>
          </div>
        </div>
        <template v-for="question in questionList" >
          <div :key="question.id" class="question_item" @click="goDetail(question)">
            <div class="title">{{ question.title }}</div>
            <div class="content">{{ question.profile }}</div>
          </div>
        </template>
        <div class="empty_container" v-if="questionList.length === 0 && !firstLoading">
          <img src="@/assets/image/empty.png" alt="">
          <div>暂无常见问题</div>
        </div>
        <div v-if="!noMore" style="padding: 10px" class="loading_text">
          <div v-if="!isPullUpLoad">
            <span>上拉加载更多</span>
          </div>
          <div v-else>
            <span>加载中...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-if="firstLoading"><a-icon type="loading" /></div>
    <div class="bottom_container">
      <div @click="goProductDetail">
        <a-icon type="appstore" />
        <span>产品信息</span>
      </div>
      <div @click="goHistory">
        <a-icon type="customer-service" />
        <span>维修记录</span>
      </div>
      <div class="current">
        <a-icon type="interaction" />
        <span>常见问题</span>
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request';
import BScroll from '@better-scroll/core'
import PullDown from '@better-scroll/pull-down'
import Pullup from '@better-scroll/pull-up'
BScroll.use(PullDown)
BScroll.use(Pullup)
export default {
  data () {
    return {
      bscroll: null,
      beforePullDown: true,
      isPullingDown: false,
      isPullUpLoad: false,
      enterprise_id: 0,
      page: 1,
      limit: 30,
      questionList: [],
      noMore: true,
      firstLoading: true,
      form: {
        title: ''
      },
      title: '',
      qrId: 0
    }
  },
  created: async function () {
    this.qrId = this.$route.query.qr_id
    this.enterprise_id = this.$route.query.enterpriseId
    this.single_product_id = this.$route.query.singleProductId
    await this.loadData()
  },
  mounted () {
    this.initBscroll()
  },
  methods: {
    goHistory () {
      this.$router.replace({ path: '/h', query: {
        enterpriseId: this.enterprise_id,
        singleProductId: this.single_product_id,
        qr_id: this.qrId
      }})
    },
    goProductDetail () {
      this.$router.replace({ path: '/s', query: { 
        qr_id: this.qrId
      }})
    },
    goDetail (question) {
      this.$router.push({ path: '/qd', query: { 
        question_id: question.id
      }})
    },
    async loadData (type = '') {
      await request({
        url: 'knowledge/public/list',
        data: { enterprise_id: this.enterprise_id, pageNo: this.page, pageSize: this.limit, title: this.title }
      }).then(r => {
        this.firstLoading = false
        if (type === 'refresh') {
          this.questionList = r.data.data
        } else {
          this.questionList = this.questionList.concat(r.data.data);
        }
        if (r.data.data.length > 0) {
          this.bscroll.openPullUp()
          this.page++
          this.noMore = false
        } else {
          this.bscroll.closePullUp()
          this.noMore = true
        }
      })
    },
    sub () {
      this.page = 1
      this.title = this.form.title
      this.loadData('refresh')
      return false
    },
    search () {
      return false
    },
    initBscroll() {
      this.bscroll = new BScroll(this.$refs.wrapper, {
        scrollY: true,
        bounceTime: 800,
        useTransition: false,
        pullDownRefresh: {
          threshold: 70,
          stop: 56
        },
        click: true,
        pullUpLoad: true
      })
      this.bscroll.on('pullingDown', this.pullingDownHandler)
      this.bscroll.on('pullingUp', this.pullingUpHandler)
    },
    async pullingDownHandler() {
      this.beforePullDown = false
      this.isPullingDown = true

      // 请求数据
      this.page = 1
      await this.loadData('refresh')

      this.isPullingDown = false
      this.finishPullDown()
    },
    finishPullDown() {
      this.bscroll.finishPullDown()
      setTimeout(() => {
        this.beforePullDown = true
        this.bscroll.refresh()
      }, 800 + 100)
    },
    async pullingUpHandler() {
      this.isPullUpLoad = true
      await this.loadData()
      if (!this.noMore) {
        this.bscroll.finishPullUp()
      }
      this.bscroll.refresh()
      this.isPullUpLoad = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-input{
  font-size: 16px;
  color: #222 !important;
}
.container{
  padding: 8px;
  padding-top: 98px;
  .search_container{
    position: fixed;
    top: 50px;
    left: 0;
    height: 40px;
    background-color: #3263f2;
    width: 100%;
    z-index: 666;
    .search_box{
      width: 90%;
      margin: 0 auto;
      /deep/ .ant-input{
        border-radius: 30px;
        background-color: #2359fa;
        color: #fff !important;
        height: 30px;
        line-height: 30px;
        padding-left: 36px;
        padding-top: 0;
        padding-bottom: 0;
        border: 1px solid #2359fa;
      }
      /deep/ .ant-input:focus{
        box-shadow: none;
      }
    }
  }
  .question_item{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    .title{
      color: #000;
      width: 100%;
      overflow: hidden;
      height: 24px;
      line-height: 24px;
      font-size: 18px;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
    .content{
      font-size: 16px;
      margin-top: 2px;
      color: #a9a9a9;
      height: 42px;
      line-height: 22px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-align: justify;
      -webkit-box-orient: vertical;
    }
  }
  .wrapper{
    position: fixed;
    bottom: 60px;
    top: 98px;
    height: calc(100% - 168px);
    width: calc(100% - 20px);
    .pulldown-wrapper{
      position: absolute;
      width: 100%;
      padding: 22px;
      box-sizing: border-box;
      transform: translateY(-100%) translateZ(0);
    }
    .loading_text{
      text-align: center;
      color: #999;
    }
  }
}
.empty_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    margin-top: 30%;
    width: 80%;
  }
}
.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240,242,245, 1);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
</style>
